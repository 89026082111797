<template>
  <b-container fluid class="bv-row">
    <b-row class="">
      <b-col cols="12" style="padding-left:5px; padding-right:5px; margin-top:5px; margin-bottom:5px">
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>



  export default {
    name: 'public-container',
    components: {

    },

    data() {
      return {
      }
    },

    methods: {
    },
    mounted: function onMount() {
    }
  }
  /*<template>
    <b-container fluid class="bv-row">
      <b-row>
        <b-col offset="2" cols="8">
          <transition>
            <keep-alive>
              <router-view></router-view>
            </keep-alive>
          </transition>
      </b-col>

    </b-row>
  </b-container>
  </template>*/
</script>
